export default function RefAndComp(){
    return(
      <div>
          <p></p> 
          <div>References and contributions to those who made their images and content available include: 
          <ol> 
              <li><a href="https://unsplash.com/photos/PoAC9dCnofk?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Beer Cooler" by Justin Sanchez </a></li> 
              <li><a href="https://unsplash.com/photos/iiNiGqYzuN0?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Beer Sign" by James Lee </a></li> 
              <li><a href="https://unsplash.com/photos/xtxeXKdwgI0?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Close Up Bottles" by Thanh Serious </a></li> 
              <li><a href="https://unsplash.com/photos/Kzl0IX8fJxQ?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Tap Close Up" by Lucas Santos</a></li> 
              <li><a href="https://unsplash.com/photos/XStd_1-BNi8?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Pour" by Merritt Thomas</a></li> 
              <li><a href="https://unsplash.com/photos/fcyqGNfNJQk?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Barrels" by August Phlieger </a></li> 
              <li><a href="https://unsplash.com/photos/OMIgwm1i_NY?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Hops and Beer" by Missy Fant </a></li> 
              <li><a href="https://unsplash.com/photos/gvm_Kmm3-9o?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Cheers" by Des Récits </a></li> 
              <li><a href="https://unsplash.com/photos/1MqDCpA-2hU?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink" target="_blank">"Beer Splash" by Pradnyal Gandhi </a></li> 
              <li>Beer descriptions and site were inspired by Castle Danger Brewery in Two Harbors, MN (visit them <a href="https://www.castledangerbrewery.com/">here</a>)</li> 
              </ol></div> 
          <div>This project was fun and I appreciate all the resources made available to me!!</div>
      </div>  
    );
}